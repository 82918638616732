import React from "react"
import { graphql } from "gatsby"
import widont from "widont"

import Layout from "../components/layout"
import ZeusModules from "../components/zeusModules"
import styles from "./tertiary-page.module.scss"

const TertiaryPage = props => {
  const entry = props.data.craft.entry
  const seo = entry.seomatic

  return (
    <Layout seo={seo}>
      {/* <div className="page-content-module">
        <div className="wrapper">
          <div className="columns is-desktop">
            <div className="column is-10-tablet is-offset-1-desktop is-6-widescreen">
              <h1 className="h-2 mb-10">{page.title}</h1>
              {page.body && (
                <div
                  className="user-content user-content--max-width-headings mb-12"
                  dangerouslySetInnerHTML={{
                    __html: page.body,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div> */}
      {/* .page-content-module */}
      <div className={`page-content-module ${styles.pageContent}`}>
        <div className="wrapper">
          <div
            className={`${styles.title} ${
              entry.hideTitle ? "screen-reader-only" : ""
            }`}
          >
            <div className="columns is-desktop">
              <div className="column is-7-desktop is-offset-1-desktop">
                <h1 className="h-1 mb-2">{widont(entry.title)}</h1>
                {entry.subtitle && (
                  <h2 className="body-large-after-tablet">
                    {widont(entry.subtitle)}
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>

        {entry.zeusModules && <ZeusModules data={entry.zeusModules} />}
      </div>
    </Layout>
  )
}

export default TertiaryPage

export const query = graphql`
  query($slug: String!) {
    craft {
      entry: entry(slug: [$slug], section: "tertiaryPages") {
        title
        slug
        id
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_tertiaryPages_tertiaryPages_Entry {
          subtitle
          hideTitle
          zeusModules {
            ...ZeusModules
          }
        }
      }
    }
  }
`
